import { createRouter, createWebHistory } from 'vue-router';

import CheckoutReqPay from '@/views/CheckoutReqPay';

const routes = [
  {
    path: '/:lang',
    // component: { template: '<router-view />' },
    children: [
      {
        path: 'checkout/:session_id',
        name: 'checkout-sdk',
        meta: {
          page_title: 'Checkout',
          plugin: 'checkout',
        },
        component: CheckoutReqPay,
      },
      {
        path: 'pos/attempt-detail/:attempt_id',
        name: 'payment-attempt-detail',
        meta: {
          plugin: 'pos',
        },
        component: () => import('../views/AttemptDetails.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
