<template>
  <div>
    <div style="margin-top: 100px"></div>
    <v-footer class="ottu-footer" padless dark>
      <div class="social-icons" data-test="social-icons">
        <a v-if="facebook_url" :href="facebook_url" class="social__link"
          ><i class="fab fa-facebook-f"></i
        ></a>
        <a v-if="twitter_url" :href="twitter_url" class="social__link"
          ><i class="fab fa-twitter"></i
        ></a>
        <a v-if="instagram_url" :href="instagram_url" class="social__link"
          ><i class="fab fa-instagram"></i
        ></a>
      </div>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: 'PaymentFooter',
  props: {
    twitter_url: String,
    instagram_url: String,
    facebook_url: String,
  },
};
</script>

<style scoped>
body {
  padding-bottom: 30px;
}
.ottu-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
}
</style>
