<template>
  <v-app>
    <!-- <NavBar
      v-if="!new_layout"
      :logo_url="unit_details.logo_url"
      :title_en="unit_details.title_en"
      :title_ar="unit_details.title_ar"
      :website="unit_details.website"
      :twitter_url="unit_details.twitter_url"
      :instagram_url="unit_details.instagram_url"
      :facebook_url="unit_details.facebook_url"
      :show_unit_title="unit_details.show_unit_title"
      :header_color="unit_details.header_color"
    /> -->
    <AttemptHeader
      v-if="new_layout"
      :logo_url="unit_details.logo_url"
      :header_color="unit_details.header_color"
      :attempt="attempt"
    />
    <v-main v-if="new_layout">
      <slot> </slot>
    </v-main>
    <v-container fluid v-else>
      <slot></slot>
    </v-container>
    <PaymentFooter
      v-if="new_layout"
      :twitter_url="unit_details.twitter_url"
      :instagram_url="unit_details.instagram_url"
      :facebook_url="unit_details.facebook_url"
    />
  </v-app>
</template>

<script>
// import NavBar from './NavBar.vue'
import AttemptHeader from './AttemptHeader.vue';
import PaymentFooter from './PaymentFooter.vue';

export default {
  name: 'CustomerPage',
  props: {
    unit_details: Object,
    new_layout: Boolean,
    attempt: Boolean,
  },
  components: { AttemptHeader, PaymentFooter },
};
</script>
